import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { dispatch } from 'reffects';
import ContactCard from 'design-system/components/ContactCard/ContactCard';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import Button, { BUTTON_SIZE } from 'design-system/components/Button';
import Tag, {
  TAG_ICON_POSITION,
  TAG_VARIANT,
} from 'design-system/components/Tag';
import Tooltip from 'design-system/components/Tooltip';
import FormattedMessageWithValidation from '../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  hasLeadPlusSelector,
  hasQualificationSelector,
  isLoadingSelector,
  leadIsHotSelector,
  leadIsSuperHotSelector,
  leadSelector,
} from '../../../../selectors';
import VerifiableDatum from './VerifiableDatum';
import QrModal from './QrModal';
import { OPEN_DIALOG } from '../../../../../../events/dialogs';
import { HOTNESS_EVENT_IDENTIFIER, QR_MODAL } from './constants';
import ContactQr from '../../../ContactQr';
import VerifiableEmail from './VerifiableEmail';
import LeadFeedback from '../../../LeadFeedback';

const Wrapper = styled(ContactCard)`
  width: 100%;
`;

const QrButton = styled(Button).attrs({ icon: <Icon glyph="qr" /> })`
  align-self: flex-end;

  ${Button.mods.size(BUTTON_SIZE.LARGE)}
  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.BASE)}
  }
`;

const LeadName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.S)};
`;

const FeedbackRounder = styled.div`
  > div {
    border-radius: ${spacing.value(SPACING_SIZE.S)};
  }
`;

export default function LeadContact() {
  const isLoading = useSelector(isLoadingSelector);
  const lead = useSelector(leadSelector);
  if (isLoading) {
    return <ContactCard.Skeleton qr={<ContactQr.Skeleton />} $width={210} />;
  }

  return (
    <Wrapper
      name={
        <LeadName>
          <span>{lead.name}</span>
          <LeadTag />
        </LeadName>
      }
    >
      {lead.countryCode && (
        <ContactCard.Datum glyph="location">
          <FormattedMessageWithValidation
            id={`country_${lead.countryCode.toLowerCase()}`}
          />
        </ContactCard.Datum>
      )}
      {lead.emails?.map((e) => (
        <VerifiableEmail
          value={e.address}
          glyph="email"
          key={e.address}
          isVerified={e.isVerified}
        />
      ))}
      {lead.phones?.map((p) => (
        <VerifiableDatum
          value={p.number}
          isVerified={p.isVerified}
          glyph="phone"
          key={p.number}
          link={`tel:${p.number}`}
        />
      ))}
      <QrButton
        onClick={() =>
          dispatch({
            id: OPEN_DIALOG,
            payload: {
              id: QR_MODAL,
              parameters: {
                name: lead.name,
                phone: lead.phones[0]?.number,
                email: lead.emails[0]?.address,
              },
            },
          })
        }
      />
      <LeadHotnessFeedback />
      <QrModal />
    </Wrapper>
  );
}

function LeadTag() {
  const isSuperHot = useSelector(leadIsSuperHotSelector);
  const isHot = useSelector(leadIsHotSelector);
  const hasLeadPlus = useSelector(hasLeadPlusSelector);
  const hasQualification = useSelector(hasQualificationSelector);
  if (isSuperHot) {
    return (
      <Tooltip
        title={
          <FormattedMessageWithValidation id="leadpage_contact_super_hot_tooltip" />
        }
      >
        <Tag
          variant={TAG_VARIANT.COMPLEMENTARY_STRAWBERRY}
          icon={<Icon glyph="superHot" />}
          iconPosition={TAG_ICON_POSITION.START}
        >
          <FormattedMessageWithValidation id="leadpage_contact_super_hot" />
        </Tag>
      </Tooltip>
    );
  }
  if (isHot) {
    return (
      <Tooltip
        title={
          <FormattedMessageWithValidation id="leadpage_contact_hot_tooltip" />
        }
      >
        <Tag
          variant={TAG_VARIANT.COMPLEMENTARY_ORANGE}
          icon={<Icon glyph="hot" />}
          iconPosition={TAG_ICON_POSITION.START}
        >
          <FormattedMessageWithValidation id="leadpage_contact_hot" />
        </Tag>
      </Tooltip>
    );
  }
  return (
    <>
      {hasLeadPlus && (
        <Tag variant={TAG_VARIANT.GOLD}>
          <FormattedMessageWithValidation id="leadpage_contact_lead_plus" />
        </Tag>
      )}
      {hasQualification && (
        <Tag variant={TAG_VARIANT.GOLD}>
          <FormattedMessageWithValidation id="leadpage_contact_qualification" />
        </Tag>
      )}
    </>
  );
}

function LeadHotnessFeedback() {
  const isSuperHot = useSelector(leadIsSuperHotSelector);
  const isHot = useSelector(leadIsHotSelector);

  if (!isSuperHot && !isHot) {
    return null;
  }

  const hotness = isSuperHot ? 'superhot' : 'hot';

  const hotnessTag = () => {
    if (isSuperHot) {
      return (
        <Tag
          variant={TAG_VARIANT.COMPLEMENTARY_STRAWBERRY}
          icon={<Icon glyph="superHot" />}
          iconPosition={TAG_ICON_POSITION.START}
        >
          <FormattedMessageWithValidation id="leadpage_contact_super_hot" />
        </Tag>
      );
    }

    return (
      <Tag
        variant={TAG_VARIANT.COMPLEMENTARY_ORANGE}
        icon={<Icon glyph="hot" />}
        iconPosition={TAG_ICON_POSITION.START}
      >
        <FormattedMessageWithValidation id="leadpage_contact_hot" />
      </Tag>
    );
  };

  return (
    <FeedbackRounder>
      <LeadFeedback
        eventIdentifier={HOTNESS_EVENT_IDENTIFIER}
        eventExtraInformation={{ hotness }}
        customTitle={
          <FormattedMessageWithValidation
            id="leadpage_hotness_poll_title"
            values={{
              hotness: hotnessTag(),
            }}
          />
        }
      />
    </FeedbackRounder>
  );
}
